import { Box, HStack, Icon, IconButton, Separator, Text } from '@chakra-ui/react';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from 'components/ui/hover-card';
import { Tag } from 'components/ui/tag';
import { add } from 'date-fns/add';
import { format } from 'date-fns/format';
import useTracking from 'hooks/useTracking';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { ConfidenceLevel } from 'types/shared-types';

type NexusPredictionsProps = {
  stateCode: string;
  countryCode: string;
  prediction: {
    predicted_month_from_today: number;
    confidence_level: number;
  };
};

const CONFIDENCE_THRESHOLDS = {
  HIGH: 80,
  MEDIUM: 50,
  MIN: 50,
} as const;

const CONFIDENCE_STYLES = {
  [ConfidenceLevel.High]: { color: 'green.400' },
  [ConfidenceLevel.Medium]: { color: 'primary.500' },
  [ConfidenceLevel.Low]: { color: 'red.500' },
} as const;

const getConfidenceLevel = (percentage: number): ConfidenceLevel => {
  if (percentage >= CONFIDENCE_THRESHOLDS.HIGH) {
    return ConfidenceLevel.High;
  }
  if (percentage >= CONFIDENCE_THRESHOLDS.MEDIUM) {
    return ConfidenceLevel.Medium;
  }
  return ConfidenceLevel.Low;
};

export const NexusPredictions = ({
  stateCode,
  countryCode,
  prediction: { confidence_level, predicted_month_from_today },
}: NexusPredictionsProps) => {
  const { track } = useTracking();
  const baseDate = new Date();
  const validPrediction = confidence_level > 0 && predicted_month_from_today > 0;

  if (!validPrediction) {
    return undefined;
  }

  const predictedMonth = add(baseDate, { months: predicted_month_from_today });
  const formattedDate = predicted_month_from_today <= 24 ? format(predictedMonth, 'MMM dd, yyyy') : '>24 months';
  const percentage = Number((confidence_level * 100).toFixed(2));
  const level = getConfidenceLevel(percentage);
  const { color } = CONFIDENCE_STYLES[level];

  return (
    <HoverCardRoot
      positioning={{ placement: 'bottom-end' }}
      onOpenChange={() => {
        try {
          track('nexus_prediction_viewed', {
            state_code: stateCode,
            country_code: countryCode,
            confidence_level,
            predicted_month_from_today,
          });
        } catch (error) {
          console.error('Failed to track nexus prediction view:', error);
        }
      }}
    >
      <HoverCardTrigger>
        <IconButton variant="transparent-with-icon" size="xs" aria-label="Nexus Predictions">
          <Icon w="5" h="5">
            <MdOutlineEventAvailable />
          </Icon>
        </IconButton>
      </HoverCardTrigger>
      <HoverCardContent background="gray.900" color="white" minWidth="320px" borderRadius="l1">
        <Box>
          <Tag variant="solid" colorPalette="blue" fontWeight="bold" fontSize="xxs" borderRadius="l1">
            BETA
          </Tag>
          <HStack justify="space-between" mt="2">
            <Text>Possible Nexus Met Date</Text>
            <Text fontWeight="medium">{formattedDate}</Text>
          </HStack>
          <Separator my="2" borderColor="gray.800" />
          <HStack>
            <Box borderRadius="2px" w="18px" h="18px" backgroundColor={color} />
            <Text>{level} Confidence</Text>
          </HStack>
        </Box>
      </HoverCardContent>
    </HoverCardRoot>
  );
};
