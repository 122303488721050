import { Flex, Text } from '@chakra-ui/react';
import { StepType, TourProvider, useTour } from '@reactour/tour';
import { Button } from 'components/ui/button';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ONBOARDING_FLAG = 'onboardingStatus';

enum OnboardingStatus {
  NOT_STARTED = 'NOT_STARTED',
  SKIPPED = 'SKIPPED',
  FINISHED = 'FINISHED',
}

const setOnboardingStateInStorage = (value: string) => {
  localStorage.setItem(ONBOARDING_FLAG, value);
  window.dispatchEvent(new Event('storage'));
};

const getOnboardingStatusInStorage = () => {
  return localStorage.getItem(ONBOARDING_FLAG);
};

export interface Step extends StepType {
  metadata?: {
    routeTo: string;
  };
}

const getStepPopOverContent = (heading: string, content: string) => {
  return (
    <Flex direction="column" gap={2}>
      <Text fontWeight="bold" fontSize={'20'}>
        {heading}
      </Text>
      <Text>{content}</Text>
    </Flex>
  );
};

const onboardingSteps: Step[] = [
  {
    selector: '[data-onboarding="Dashboard"]',
    content: () => {
      return getStepPopOverContent(
        'Dashboard',
        'The Dashboard will show your sales tax overview and provide helpful actions you can take to stay compliant.'
      );
    },
    position: 'right',
    metadata: {
      routeTo: '/source',
    },
  },
  {
    selector: '[data-onboarding="Data Sources"]',
    content: () => {
      return getStepPopOverContent(
        'Data Sources',
        'In this section you can connect with billing, payment and HR systems to fully automate your sales tax flows. You can also manually import data from a variety of sources.'
      );
    },
    position: 'right',
  },
  {
    selector: '[data-onboarding="data-import"]',
    content: () => {
      return getStepPopOverContent(
        'Import Transactions',
        'Connect your systems with no code integrations or add files manually to track Nexus and calculate tax liability.'
      );
    },
    metadata: {
      routeTo: '/products',
    },
  },
  {
    selector: '[data-onboarding="Products"]',
    position: 'right',
    content: () => {
      return getStepPopOverContent('Products', 'Review and configure products to ensure correct tax calculations.');
    },
    metadata: {
      routeTo: '/nexus',
    },
  },
  {
    selector: '[data-onboarding="Nexus"]',
    position: 'right',
    content: () => {
      return getStepPopOverContent(
        'Nexus',
        'Review and track your Nexus in real time to see a complete picture of your tax obligations.'
      );
    },
  },
  {
    selector: '[data-onboarding="nexus-table"]',
    metadata: {
      routeTo: '/registrations',
    },
    content: () => {
      return getStepPopOverContent(
        'Check your Nexus met date',
        'See when you met nexus in a specific jurisdiction to understand tax liability and registration timing.'
      );
    },
  },
  {
    selector: '[data-onboarding="Registrations"]',
    position: 'right',
    content: () => {
      return getStepPopOverContent(
        'Register in a Jurisdiction',
        'You can register quickly in any jurisdiction with the press of a button.'
      );
    },
  },
  {
    selector: '[data-onboarding="registration-table"]',
    content: () => {
      return getStepPopOverContent(
        'Registration Jurisdiction',
        'You can register quickly in any jurisdiction with the press of a button.'
      );
    },
    metadata: {
      routeTo: '/filing',
    },
  },
  {
    selector: '[data-onboarding="Filings"]',
    content: () => {
      return getStepPopOverContent(
        'Filings',
        'Review where you need to file, confirm tax liability, and track your filing history.'
      );
    },
    position: 'right',
    metadata: {
      routeTo: '/customers',
    },
  },
  {
    selector: '[data-onboarding="Customers"]',
    position: 'right',
    content: () => {
      return getStepPopOverContent(
        'Customers',
        `See a view of your customer's transactions, exemptions, and history in one place.`
      );
    },
    metadata: {
      routeTo: '/transactions',
    },
  },
  {
    selector: '[data-onboarding="Transactions"]',
    content: () => {
      return getStepPopOverContent(
        "Customer's transactions",
        `View and inspect your customer's transactions and review tax application.`
      );
    },
    position: 'right',
    metadata: {
      routeTo: '/presence',
    },
  },
  {
    selector: '[data-onboarding="Presence"]',
    content: () => {
      return getStepPopOverContent(
        'Presence',
        'Declare where you have office locations, employees, contractors, and other forms of physical presence.'
      );
    },
    position: 'right',
  },
];

const ContentComponent = (props: any) => {
  const navigate = useNavigate();
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep].content;

  return (
    <Flex direction="column" gap={2}>
      {/* Check if the step.content is a function or a string */}
      {typeof content === 'function' ? content({ ...props, someOtherStuff: 'Custom Text' }) : content}
      <Flex justifyContent="space-between">
        <Button
          onClick={() => {
            props.setIsOpen(false);
            setOnboardingStateInStorage(OnboardingStatus.SKIPPED);
          }}
        >
          {'Skip'}
        </Button>
        <Button
          onClick={() => {
            if (isLastStep) {
              props.setIsOpen(false);
              setOnboardingStateInStorage(OnboardingStatus.FINISHED);
            } else {
              if (props.steps[props.currentStep]?.metadata && props.steps[props.currentStep].metadata.routeTo) {
                props.setIsOpen(false);
                navigate(props.steps[props.currentStep].metadata.routeTo);
                return;
              }
              props.setCurrentStep((s: number) => s + 1);
            }
          }}
        >
          {isLastStep ? 'Done' : 'Next'}
        </Button>
      </Flex>
    </Flex>
  );
};

const isValidOnboardingStatus = (status: string) =>
  Object.values(OnboardingStatus).includes(status as OnboardingStatus);

const useOnboarding = () => {
  const { setIsOpen, setCurrentStep } = useTour();
  const [onboardingState, setOnboardingState] = useState<string | null>();
  const isOnboardingDone = (): boolean => {
    const state = getOnboardingStatusInStorage();
    return state === OnboardingStatus.FINISHED || state === OnboardingStatus.SKIPPED;
  };
  const isOnboardingInprogress = (): boolean => {
    const state = getOnboardingStatusInStorage();
    return state === OnboardingStatus.NOT_STARTED;
  };

  useEffect(() => {
    window.addEventListener('storage', () => {
      const newValue = localStorage.getItem(ONBOARDING_FLAG);
      if (newValue && onboardingState !== newValue && isValidOnboardingStatus(newValue)) {
        setOnboardingState(newValue);
      }
    });
  }, []);

  const setInitialOnboardingState = (status: string | null) => {
    if (status && isValidOnboardingStatus(status)) {
      setOnboardingStateInStorage(status);
    } else {
      setOnboardingStateInStorage(OnboardingStatus.NOT_STARTED);
    }
  };

  const setCurrentOnboardingStep = (step: number) => {
    if (isOnboardingDone()) {
      setIsOpen(false);
      return;
    }
    setCurrentStep(step);
    setIsOpen(true);
  };

  return { setCurrentOnboardingStep, isOnboardingInprogress, setInitialOnboardingState, onboardingState };
};

export { ContentComponent, isValidOnboardingStatus, ONBOARDING_FLAG, OnboardingStatus, onboardingSteps, TourProvider };
export default useOnboarding;
