import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NexusInstance } from 'types/shared-types';

const NEXUS_DRAWER_KEY = 'nexusDetailsId';

type UseNexusSelectionReturn = {
  selectedNexus: NexusInstance | undefined;
  handleDrawerOpen: (nexus: NexusInstance) => void;
  handleDrawerClose: () => void;
};

export const useNexusSelection = (data: NexusInstance[]): UseNexusSelectionReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nexusId = searchParams.get(NEXUS_DRAWER_KEY);
  const nexusMap = useMemo(() => Object.fromEntries(data.map(nexus => [nexus.id, nexus])), [data]);
  const selectedNexus = nexusId && nexusMap[nexusId] ? nexusMap[nexusId] : undefined;

  const handleDrawerOpen = (nexus: NexusInstance) => {
    if (nexus.id) {
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        if (nexus.id) {
          newParams.set(NEXUS_DRAWER_KEY, nexus.id);
        }
        return newParams;
      });
    }
  };

  const handleDrawerClose = () => {
    setSearchParams(prevParams => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete(NEXUS_DRAWER_KEY);
      return newParams;
    });
  };

  return {
    selectedNexus,
    handleDrawerOpen,
    handleDrawerClose,
  };
};
