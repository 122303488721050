/* eslint-disable no-nested-ternary */

import { Badge } from '@chakra-ui/react';
import { RegistrationStatus } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

export default function RegistrationStateBadge({ status }: Readonly<{ status: RegistrationStatus }>) {
  switch (status) {
    case RegistrationStatus.REGISTERED:
      return <Badge colorPalette={'green'}>{getHumanReadableString(status)}</Badge>;
    case RegistrationStatus.AWAITING_CLARIFICATION:
    case RegistrationStatus.PROCESSING:
      return <Badge colorPalette={'blue'}>{getHumanReadableString('REGISTERING')}</Badge>;
    case RegistrationStatus.VALIDATING:
      return <Badge colorPalette={'blue'}>{getHumanReadableString(status)}</Badge>;
    case RegistrationStatus.UNREGISTERED:
    case RegistrationStatus.DEREGISTERING:
    case RegistrationStatus.DEREGISTERED:
      return <Badge colorPalette={'gray'}>{getHumanReadableString(status)}</Badge>;
  }

  return null;
}
