import { HStack, IconButton, Spinner } from '@chakra-ui/react';
import { exportNexus } from 'apis/dashboard-apis';
import { ExcelIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';

const NexusHeaderToolbar = () => {
  const { orgId } = useOrg();
  const { loading, executeAction } = useAsyncActionLoader(exportNexus);
  const { handleSuccessNotification, handleFailNotification } = useHandleNotification();
  const handleExportNexus = async () => {
    try {
      await executeAction(orgId);
      handleSuccessNotification('Nexus Report Export Started. Check your email for a download link.');
    } catch (error) {
      handleFailNotification(error);
    }
  };

  return (
    <HStack>
      <Tooltip positioning={{ placement: 'bottom-end' }} content={'Export Excel'}>
        <IconButton disabled={loading} variant="plain" aria-label={'Export Nexus'} mr={1} onClick={handleExportNexus}>
          {loading ? <Spinner /> : <ExcelIcon w="6" h="6" />}
        </IconButton>
      </Tooltip>
    </HStack>
  );
};

export default NexusHeaderToolbar;
