import { useNavigate } from 'react-router-dom';
import { Period } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import { NexusDetailsCard } from './NexusDetailsCard';

type NexusPeriodInfoProps = {
  period: Period;
  index: number;
  stateCode: string;
};

const NexusPeriodInfo = ({ period, index, stateCode }: NexusPeriodInfoProps) => {
  const navigate = useNavigate();

  const handleClick = (label: string) => {
    const queryParams = new URLSearchParams({
      page: '1',
      size: '25',
      state_code: stateCode,
      country: 'US',
      date__gte: period.start_date,
      date__lte: period.end_date,
    });
    if (label === 'Marketplace') {
      queryParams.append('marketplace', 'true');
    }
    navigate(`/transactions?${queryParams.toString()}`);
  };

  const nexusPeriodData = [
    { label: 'Start Date', value: toDateShort(period.start_date) },
    { label: 'End Date', value: toDateShort(period.end_date) },
    { label: 'Transaction Amount', value: formatCurrency(period.transactions_amount) },
    { label: 'Tax Liability', value: formatCurrency(period.transactions_tax_liability_amount) },
    { label: 'Transactions', value: '', isHeader: true },
    { label: 'Total', value: period.transactions_count, isLink: true, onClick: handleClick },
    { label: 'Marketplace', value: period.transactions_marketplace, isLink: true, onClick: handleClick },
    { label: 'Excluded', value: period.transactions_exempted },
    { label: 'Included', value: period.transactions_included },
    { label: 'Economic Nexus Status', value: period.nexus_state },
  ];
  return (
    <NexusDetailsCard
      cardTitle={'Economic Nexus Periods'}
      hideCardTitle={index !== 0}
      nexusDetailsData={nexusPeriodData}
    />
  );
};

export default NexusPeriodInfo;
