import { Flex, HStack, Icon, Table, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { usePaywall } from 'app/acl/paywall';
import { InfoIcon } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Button } from 'components/ui/button';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { Tooltip } from 'components/ui/tooltip';
import { useNexusSelection } from 'hooks/useNexusSelection';
import useOnboarding from 'hooks/useOnboarding';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import isEmpty from 'lodash/isEmpty';
import { SetupPhysicalMailModal } from 'pages/Configuration/components/communications/setup-physical-mail-modal';
import RegistrationStateBadge from 'pages/Registrations/components/registration-state-badge';
import { useState } from 'react';
import { MdOutlineAnalytics } from 'react-icons/md';
import { NexusInstance, NexusStatus, RegistrationStatus } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { toDateShort } from 'utils/dates';
import { getNexusType } from 'utils/nexus';
import { formatCurrency } from 'utils/utils';

import { NexusStateBadge } from './nexus-state-badge';
import NexusDetailsDrawer from './NexusDetailsDrawer';

const NUMERIC_COL = ['Collected Tax', 'Tax Liability'];
const TABLE_HEAD = ['Country', 'Jurisdiction', 'Type', 'Met Date', 'Collected Tax', 'Tax Liability', 'Status'];
const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;
const ONBOARDING_DATA: NexusInstance = {
  country_code: 'United States',
  state_name: 'Alabama',
  state_code: 'AL',
  transactions_amount: 120,
  status: NexusStatus.NOT_EXPOSED,
  nexus_met_date: '2024-01-01',
  tax_liability: 0,
  physical: true,
  services: true,
  digital: true,
  treatment_of_exempt_transactions: '',
  trigger: '',
  sales_or_transactions: '',
  threshold_sales: 0,
  threshold_transactions: null,
  start_date: '',
  transaction_count: 0,
  previous_transaction_count: 0,
  previous_transactions_amount: 0,
  nexus_met: false,
  period_model: '',
  period_start_date: '',
  period_end_date: '',
  previous_period_start_date: null,
  previous_period_end_date: null,
  most_recent_transaction_date: null,
  periods: null,
  collected_tax_nexus_met: false,
  collected_tax_nexus_met_date: null,
};
const COLUMN_WIDTHS = {
  Country: '130px',
  Jurisdiction: '130px',
  Type: '201px',
  Met_Date: '180px',
  Collected_Tax: '150px',
  Tax_Liability: '170px',
  Status: '130px',
};

export const ExposedNexusTable = ({
  data,
  onboardingComplete,
  physicalMailComplete,
  isPending,
}: {
  data: NexusInstance[];
  onboardingComplete: boolean;
  physicalMailComplete: boolean;
  isPending: boolean;
}) => {
  const isDataEmpty = !data || data.length === 0;
  const { isOnboardingInprogress } = useOnboarding();
  const { isTest } = useOrg();
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);
  const { selectedNexus, handleDrawerClose, handleDrawerOpen } = useNexusSelection(data);

  const handleVisibleColumnsChange = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const isRegistrationAllowed = isTest || onboardingComplete;

  //this is to fix the alignment of nexus details icon and register button
  const isRegButtonShown = data.some(nexus => nexus.status === NexusStatus.EXPOSED && !nexus.registration?.id);

  /**
   * Registration is allowed if the organization details and bank details are complete and billing plan is not FREE
   * use onboarding step status api to check if the organization details and bank details are complete
   */

  if (isOnboardingInprogress()) {
    return (
      <KDataTable
        onboardingTable={'Nexus'}
        headers={TABLE_HEAD}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        showColumnFilter
      >
        <NexusTableRow nexus={ONBOARDING_DATA} visibleColumns={visibleColumns} />
      </KDataTable>
    );
  }

  if (isDataEmpty && !isPending) {
    return <TableEmptyState tableName="Nexus" />;
  }

  const getHeaderRightIcon = (header: string) => {
    if (header === 'Tax Liability') {
      return (
        <Tooltip content={'This is the tax liability from the date nexus was established.'}>
          <Icon fontSize={'18px'}>
            <InfoIcon />
          </Icon>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      <KDataTable
        headers={TABLE_HEAD}
        numericCols={NUMERIC_COL}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        showColumnFilter
        getHeaderRightIcon={getHeaderRightIcon}
        columnWidths={COLUMN_WIDTHS}
        isPending={isPending}
      >
        {data.map((nexus: NexusInstance) => {
          return (
            <NexusTableRow
              key={`${nexus.country_code}-${nexus.state_name}`}
              nexus={nexus}
              onDrawerOpen={handleDrawerOpen}
              isRegistrationAllowed={isRegistrationAllowed}
              visibleColumns={visibleColumns}
              physicalMailComplete={physicalMailComplete}
              isRegisterButtonShown={isRegButtonShown}
            />
          );
        })}
        {isPending && <TableRowSkeleton length={25} columns={visibleColumns} showEmptyColumn={true} />}
      </KDataTable>

      {selectedNexus && !isEmpty(selectedNexus.periods) && (
        <NexusDetailsDrawer isOpen onClose={handleDrawerClose} nexus={selectedNexus} />
      )}
    </>
  );
};

type NexusTableRowProps = {
  nexus: NexusInstance;
  isRegistrationAllowed?: boolean;
  onDrawerOpen?: (nexus: NexusInstance) => void;
  visibleColumns?: string[];
  physicalMailComplete?: boolean;
  isRegisterButtonShown?: boolean;
};

const NexusTableRow = ({
  nexus,
  onDrawerOpen,
  isRegistrationAllowed = false,
  visibleColumns,
  physicalMailComplete,
  isRegisterButtonShown,
}: NexusTableRowProps) => {
  const {
    id,
    country_code,
    state_code,
    tax_liability,
    nexus_met_date,
    physical_nexus_met = false,
    economic_nexus_met = false,
    registration,
    status,
    collected_tax_nexus_met = false,
    collected_tax_enabled = false,
    collected_tax_nexus_met_date,
    physical_nexus_met_date,
    economic_nexus_met_date,
    imported_tax_liability,
    is_vda_eligible = false,
  } = nexus;
  const [, setSearchParams] = useQueryParam('');
  const { isPaidUser } = usePaywall();
  const { isTest } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();

  const openRegistrationEdit = async () => {
    if (id) {
      setSearchParams({ requestRegistration: 'true', nexusId: id });
    }
  };

  const handleRegisterClick = async () => {
    if (!isPaidUser && !isTest) {
      setSearchParams({ openPricingModal: 'true' });
    } else if (isPaidUser && !physicalMailComplete && !isTest) {
      onOpen();
    } else {
      openRegistrationEdit();
    }
  };

  const displayRegisterButton =
    (status === NexusStatus.EXPOSED && !registration?.id) || registration?.status === RegistrationStatus.DEREGISTERED;

  const showNexusMetTooltip =
    [collected_tax_nexus_met_date, physical_nexus_met_date, economic_nexus_met_date].filter(Boolean).length >= 2;

  const getNexusMetTooltip = () => {
    return (
      <>
        {physical_nexus_met_date && (
          <>
            Physical Nexus Met Date: {toDateShort(physical_nexus_met_date)}
            <VStack />
          </>
        )}
        {economic_nexus_met_date && (
          <>
            Economic Nexus Met Date: {toDateShort(economic_nexus_met_date)}
            <VStack />
          </>
        )}
        {collected_tax_nexus_met_date && (
          <>
            Collected Tax Nexus Met Date: {toDateShort(collected_tax_nexus_met_date)}
            <VStack />
          </>
        )}
      </>
    );
  };

  return (
    <Table.Row>
      {visibleColumns?.includes('Country') && (
        <Table.Cell>
          <Text lineClamp={1}>{getCountryDisplay(country_code)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Jurisdiction') && (
        <Table.Cell>
          <HStack gap={1} alignItems={'center'}>
            <Text>{state_code}</Text>
            {collected_tax_enabled && !registration?.id && collected_tax_nexus_met && (
              <Tooltip
                openDelay={100}
                content={
                  'Collected Tax nexus is established when you collect sales tax in a jurisdiction without meeting economic or physical nexus criteria. Once this nexus is established, you must register and continue collecting sales tax in the jurisdiction.'
                }
                positioning={{ placement: 'bottom-end' }}
                contentProps={{ maxWidth: '384px', whiteSpace: 'normal' }}
              >
                <Icon w="4.5" h="4.5">
                  <InfoIcon />
                </Icon>
              </Tooltip>
            )}
          </HStack>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Type') && (
        <Table.Cell>
          <Text>{getNexusType(physical_nexus_met, economic_nexus_met, collected_tax_nexus_met ?? false)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Met Date') && (
        <Table.Cell>
          <Tooltip
            disabled={!showNexusMetTooltip}
            content={getNexusMetTooltip()}
            positioning={{ placement: 'right-start' }}
          >
            <Text>{toDateShort(nexus_met_date)}</Text>
          </Tooltip>
        </Table.Cell>
      )}

      {visibleColumns?.includes('Collected Tax') && (
        <Table.Cell textAlign="end">
          <Text>{formatCurrency(imported_tax_liability)}</Text>
        </Table.Cell>
      )}

      {visibleColumns?.includes('Tax Liability') && (
        <Table.Cell textAlign="end">
          <Text>{formatCurrency(tax_liability)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Status') && (
        <Table.Cell>
          {registration?.id && registration?.status === RegistrationStatus.VALIDATING ? (
            <RegistrationStateBadge status={RegistrationStatus.REGISTERED} />
          ) : (
            <NexusStateBadge status={status as NexusStatus} />
          )}
        </Table.Cell>
      )}
      <Table.Cell>
        <Flex justifyContent={'space-between'} width={'100%'} maxW={'220px'} align={'center'}>
          <Flex justifyContent={!isRegisterButtonShown ? 'flex-end' : 'flex-start'} width={'100%'}>
            <Tooltip
              content={
                !isEmpty(nexus.periods)
                  ? `Economic Nexus Details`
                  : 'Economic Nexus Details are unavailable because there are no transactions for this jurisdiction yet'
              }
            >
              <Icon
                w={6}
                h="6"
                cursor={'pointer'}
                onClick={ev => {
                  ev.stopPropagation();
                  onDrawerOpen?.(nexus);
                }}
                opacity={!isEmpty(nexus.periods) ? 1 : 0.5}
              >
                <MdOutlineAnalytics />
              </Icon>
            </Tooltip>
          </Flex>
          <Tooltip
            content={`Organization details, Bank details and Billing details are required to register.`}
            disabled={isRegistrationAllowed}
            positioning={{ placement: 'left' }}
          >
            <Button
              width={'5rem'}
              variant="outline"
              disabled={!isRegistrationAllowed}
              onClick={handleRegisterClick}
              display={displayRegisterButton ? 'block' : 'none'}
            >
              Register
            </Button>
          </Tooltip>
          {open && (
            <SetupPhysicalMailModal isOpen={open} onClose={onClose} nexusId={id} isVdaEligible={is_vda_eligible} />
          )}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};
