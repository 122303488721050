import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { NexusStatus } from 'types/shared-types';

import { NexusStateBadge } from '../nexus-state-badge';

type NexusDetailsCardProps = {
  cardTitle: string;
  hideCardTitle?: boolean;
  nexusDetailsData: {
    label: string;
    value: string | number;
    isHeader?: boolean;
    isLink?: boolean;
    onClick?: (label: string) => void;
  }[];
  showMiddleText?: boolean;
  middleText?: string;
  stateCode?: string;
};

export const NexusDetailsCard = ({
  cardTitle,
  nexusDetailsData,
  hideCardTitle,
  showMiddleText,
  middleText,
}: NexusDetailsCardProps) => {
  const getGridTemplateColumns = () => {
    if (cardTitle === 'Economic Nexus Periods') {
      return 'repeat(4, 1fr)';
    }
    if (cardTitle === 'Economic Nexus Calculation Details') {
      return 'repeat(3, 1fr)';
    }
    return `repeat(${nexusDetailsData.length}, 1fr)`;
  };

  const gridTemplateCol = getGridTemplateColumns();

  const firstThreeItems = showMiddleText ? nexusDetailsData.slice(0, 3) : nexusDetailsData;
  const remainingItems = showMiddleText ? nexusDetailsData.slice(3) : [];

  return (
    <Flex direction={'column'} gap={2}>
      {!hideCardTitle && (
        <Text fontSize="md" fontWeight={'500'} color={'gray.900'}>
          {cardTitle}
        </Text>
      )}
      <Grid
        border={'1px solid #CFD0D8'}
        borderRadius={'4px'}
        p={3}
        columnGap={4}
        rowGap={3}
        gridTemplateColumns={{
          base: gridTemplateCol,
          md: gridTemplateCol,
          sm: cardTitle === 'Periods' ? `repeat(2, 1fr)` : 'none',
        }}
        mb={2}
        css={{
          overflowX: 'scroll',

          md: {
            overflowX: 'hidden',
          },
        }}
      >
        {firstThreeItems.map(({ label, value, isHeader, isLink, onClick }, index) => (
          <Fragment key={index}>
            {isHeader && (
              <GridItem display={'block'} colSpan={4} mt={1}>
                <Text fontSize="md" fontWeight={'500'} color={'gray.900'} display={'block'}>
                  {label}
                </Text>
              </GridItem>
            )}
            {!isHeader && (
              <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Text w={'max-content'} color="#383D58">
                  {label}
                </Text>
                <Text
                  fontWeight="medium"
                  mt={1}
                  textDecoration={isLink ? 'underline' : 'none'}
                  onClick={onClick ? () => onClick(label) : undefined}
                  cursor={isLink ? 'pointer' : 'default'}
                >
                  {label === 'Economic Nexus Status' ? <NexusStateBadge status={value as NexusStatus} /> : value}
                </Text>
              </GridItem>
            )}
          </Fragment>
        ))}
        {showMiddleText && (
          <GridItem colSpan={4}>
            <Text fontSize="sm" color={'gray.900'}>
              {middleText}
            </Text>
          </GridItem>
        )}
        {remainingItems.map(({ label, value, isHeader, isLink, onClick }, index) => (
          <Fragment key={index}>
            {isHeader && (
              <GridItem display={'block'} colSpan={4} mt={1}>
                <Text fontSize="md" fontWeight={'500'} color={'gray.900'} display={'block'}>
                  {label}
                </Text>
              </GridItem>
            )}
            {!isHeader && (
              <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Text w={'max-content'} color="#383D58">
                  {label}
                </Text>
                <Text
                  fontWeight="medium"
                  mt={1}
                  textDecoration={isLink ? 'underline' : 'none'}
                  onClick={onClick ? () => onClick(label) : undefined}
                  cursor={isLink ? 'pointer' : 'default'}
                >
                  {label === 'Economic Nexus Status' ? <NexusStateBadge status={value as NexusStatus} /> : value}
                </Text>
              </GridItem>
            )}
          </Fragment>
        ))}
      </Grid>
    </Flex>
  );
};
