import { Badge } from '@chakra-ui/react';
import { NexusStatus } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

export const NexusStateBadge = ({ status }: Readonly<{ status: NexusStatus }>) => {
  switch (status) {
    case NexusStatus.EXPOSED:
      return <Badge colorPalette={'red'}>{getHumanReadableString(status)}</Badge>;
    case NexusStatus.NOT_EXPOSED:
      return <Badge colorPalette={'gray'}>{getHumanReadableString(status)}</Badge>;
    case NexusStatus.APPROACHING:
      return <Badge colorPalette={'lightYellow'}>{getHumanReadableString(status)}</Badge>;
    case NexusStatus.REGISTERED:
      return <Badge colorPalette={'green'}>{getHumanReadableString(status)}</Badge>;
    case NexusStatus.PENDING_REGISTRATION:
      return <Badge colorPalette={'blue'}>{getHumanReadableString('Registering')}</Badge>;
  }
  return null;
};
