import { Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { ArrowLeft } from 'components/icons';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { NexusInstance, SalesOrTransactions } from 'types/shared-types';
import { Maybe } from 'types/utils';
import { formatCurrency, formatNumber } from 'utils/utils';

import { NexusDetailsCard } from './NexusDetailsCard';
import NexusPeriodInfo from './NexusPeriodInfo';

type NexusDetailsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  nexus: NexusInstance;
};

const getNexusCalculationMethod = (nexus: Maybe<NexusInstance>) => {
  switch (nexus?.period_model) {
    case 'PREVIOUS_12_MONTHS':
      return 'Preceding 12 Month Period';
    case 'PREVIOUS_4_QUARTERS':
      return 'The 12 Month Period Ending With The Last Completed Calendar Quarter';
    case 'PREVIOUS_4_QUARTERS_OFFSET':
      return 'Preceding 4 Sales Tax Quarters OFFSET';
    case 'PRECEDING_YEAR_FROM_OCTOBER':
      return '12 Month Period Ending on September 30';
    case 'CURRENT_OR_PREVIOUS':
      return 'Previous or Current Calendar Year';
    default:
      return 'Unknown';
  }
};

const getTreatmentOfExemptTransactions = (nexus: Maybe<NexusInstance>) => {
  return nexus?.treatment_of_exempt_transactions === 'INCLUDED' ? 'Yes' : 'No';
};

const NexusDetailsDrawer = ({ isOpen, onClose, nexus }: NexusDetailsDrawerProps) => {
  const {
    state_name,
    state_code,
    periods,
    total_transactions = 0,
    total_transactions_included = 0,
    total_transactions_exempted = 0,
    total_transactions_marketplace = 0,
  } = nexus;

  const nexusCalculationInfos = [
    { label: 'Marketplace Included', value: total_transactions_marketplace > 0 ? 'Yes' : 'No' },
    { label: 'Exempt Included', value: getTreatmentOfExemptTransactions(nexus) },
    { label: 'Measurement Period', value: getNexusCalculationMethod(nexus) },
    { label: 'Dollar Threshold', value: formatCurrency(nexus?.threshold_sales) },
    {
      label: 'Transaction Threshold',
      value:
        nexus?.sales_or_transactions === SalesOrTransactions.SALES
          ? 'No Transactions Threshold'
          : formatNumber(nexus?.threshold_transactions, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
    },
  ];

  const nexusTransactionInfos = [
    { label: 'Total', value: formatNumber(total_transactions, { maximumFractionDigits: 0 }) },
    {
      label: 'Marketplace',
      value: formatNumber(total_transactions_marketplace, { maximumFractionDigits: 0 }),
    },
    { label: 'Excluded', value: formatNumber(total_transactions_exempted, { maximumFractionDigits: 0 }) },
    { label: 'Included', value: formatNumber(total_transactions_included, { maximumFractionDigits: 0 }) },
  ];

  const getText = () => {
    if (nexus?.sales_or_transactions === SalesOrTransactions.BOTH) {
      return `In ${state_name}, Economic nexus is met when both of the below thresholds are met.`;
    } else if (nexus?.sales_or_transactions === SalesOrTransactions.EITHER) {
      return `In ${state_name}, Economic nexus is met when either of the thresholds below is met.`;
    } else if (nexus?.sales_or_transactions === SalesOrTransactions.SALES) {
      return `In ${state_name}, Economic nexus is met when the dollar threshold below is met.`;
    }
    return undefined;
  };

  return (
    <DrawerRoot
      open={isOpen}
      placement="end"
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={{ base: 'lg', md: 'lg', sm: 'sm' }}
      restoreFocus={false}
    >
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader color={'gray.900'} fontSize={'20px'} px={4} pb={2}>
          <HStack>
            <IconButton size="sm" aria-label="back" variant={'ghost'} onClick={onClose} alignSelf={'flex-start'}>
              <ArrowLeft size="xl" />
            </IconButton>
            <Stack>
              <Heading fontWeight={500} fontSize={'20px'}>
                {state_name} Economic Nexus Details
              </Heading>
              <Text fontSize="sm">
                Details about economic nexus criteria and calculations across different economic nexus periods
              </Text>
            </Stack>
          </HStack>
        </DrawerHeader>

        <DrawerBody display={'flex'} flexDir={'column'} gap={3} px={4}>
          <NexusDetailsCard
            cardTitle={'Economic Nexus Calculation Details'}
            nexusDetailsData={nexusCalculationInfos}
            showMiddleText={true}
            middleText={getText()}
            stateCode={state_code}
          />
          <NexusDetailsCard
            cardTitle={'Transactions to Date'}
            nexusDetailsData={nexusTransactionInfos}
            stateCode={state_code}
          />

          {Array.isArray(periods) &&
            periods.map((period, index) => (
              <NexusPeriodInfo key={index} period={period} index={index} stateCode={state_code} />
            ))}
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};

export default NexusDetailsDrawer;
