const getNexusType = (isPhysical: boolean, isEconomic: boolean, isCollected: boolean): string => {
  const nexusTypes: { [key: string]: boolean } = {
    Physical: isPhysical,
    Economic: isEconomic,
    'Collected Tax': isCollected,
  };

  return Object.keys(nexusTypes)
    .filter(key => nexusTypes[key])
    .join(' & ');
};

export { getNexusType };
