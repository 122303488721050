import { Flex, HStack, Stack, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, getNexus, NEXUS_STATE_KEY, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { getInitialImportStatus, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import { usePaywall } from 'app/acl/paywall';
import AppHeader from 'app/app-header';
import NexusHeaderToolbar from 'app-header-toolbar/nexus-tracking-toolbar';
import AlertBanner from 'components/alert/alert';
import { Button } from 'components/ui/button';
import { INITIAL_FILTERED_NEXUS } from 'constants/nexus';
import { useOrg } from 'hooks/useOrg';
import { SetupPhysicalMailModal } from 'pages/Configuration/components/communications/setup-physical-mail-modal';
import { useSearchParams } from 'react-router-dom';
import { FilteredNexus } from 'types/nexus';
import { OnBoardingStepStatus } from 'types/onboarding';
import { NexusInstance, NexusStatus, PhysicalMailStatus } from 'types/shared-types';

import { ExposedNexusTable } from './components/exposed-nexus-table';
import { NonExposedNexusTable } from './components/non-exposed-nexus-table';

const Nexus = () => {
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const [, setSearchParams] = useSearchParams();
  const { open, onOpen, onClose } = useDisclosure();

  const { isLoading, data: filteredNexus = INITIAL_FILTERED_NEXUS } = useQuery<NexusInstance[], unknown, FilteredNexus>(
    {
      queryKey: [NEXUS_STATE_KEY, orgId],
      queryFn: async () => {
        const data = await getNexus(orgId);
        return data;
      },
      select: data => {
        return data.reduce<FilteredNexus>((acc, curr) => {
          const { status, processing_status, transaction_count } = curr;
          const isExposed = [NexusStatus.EXPOSED, NexusStatus.PENDING_REGISTRATION, NexusStatus.REGISTERED].includes(
            status as NexusStatus
          );
          return {
            ...acc,
            exposed: isExposed ? [...acc.exposed, curr] : acc.exposed,
            non_exposed: !isExposed ? [...acc.non_exposed, curr] : acc.non_exposed,
            isExposedNexusWaiting: isExposed && processing_status === 'NOT_READY' && transaction_count > 0,
            isNonExposedNexusWaiting: !isExposed && processing_status === 'NOT_READY' && transaction_count > 0,
          };
        }, INITIAL_FILTERED_NEXUS);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isInitialStatusLoading, data: statusData } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, 'TRANSACTION_STATUS', orgId],
    queryFn: async () => {
      const res = await getInitialImportStatus(orgId);
      return res.data;
    },
  });

  const { data: stepData, isLoading: isStepStatusLoading } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data satisfies OnBoardingStepStatus;
    },
    enabled: !!orgId,
  });

  const onboardingComplete = stepData?.bank_details_status && stepData?.organization_details_status;
  const physicalMailComplete =
    stepData?.physical_mail_address_status === (PhysicalMailStatus.complete || PhysicalMailStatus.verify);

  const handleRegisterClick = async () => {
    if (!isPaidUser && !isTest) {
      setSearchParams({ openPricingModal: 'true' });
    } else if (isPaidUser && !physicalMailComplete && !isTest) {
      onOpen();
    } else {
      setSearchParams({ requestRegistration: 'true' });
    }
  };

  const notReadyAlertContent = (
    <AlertBanner
      width={{ xl: '75%' }}
      message="We are not able to calculate Nexus for some jurisdictions because there are either too many unapproved
          Products or Invalid addresses"
    />
  );

  const transactionImportAlertContent = (
    <AlertBanner
      width={{ xl: '75%' }}
      message="Transaction import is still in progress, so the numbers may not be the latest. We’ll email you once the
            import is complete."
    />
  );

  const isPending = isLoading || isInitialStatusLoading || isStepStatusLoading;

  const tabs = {
    byId: {
      'exposed-nexus-header': {
        value: 'exposed-nexus-header',
        title: `Exposed (${filteredNexus.exposed.length})`,
        content: (
          <Stack height="100%" key="exposed-nexus">
            {filteredNexus.isExposedNexusWaiting && notReadyAlertContent}
            {!statusData?.initial_import_complete_status && transactionImportAlertContent}
            <ExposedNexusTable
              data={filteredNexus.exposed}
              onboardingComplete={onboardingComplete}
              physicalMailComplete={physicalMailComplete}
              isPending={isPending}
            />
          </Stack>
        ),
      },
      'non-exposed-nexus-header': {
        value: 'non-exposed-nexus-header',
        title: `Not Exposed (${filteredNexus.non_exposed.length})`,
        content: (
          <Stack height="100%" key="non-exposed-nexus">
            <Flex
              direction={{ base: 'column', xl: 'row' }}
              gap={{ base: '2', xl: '0' }}
              align={{ base: 'flex-start', xl: 'center' }}
              justify="space-between"
            >
              {filteredNexus.isNonExposedNexusWaiting && notReadyAlertContent}
              {!statusData?.initial_import_complete_status && transactionImportAlertContent}
              {isPaidUser && filteredNexus.non_exposed.length > 0 && (
                <Button
                  size="xs"
                  fontSize="md"
                  variant="outline"
                  onClick={() => {
                    handleRegisterClick();
                  }}
                >
                  Register
                </Button>
              )}
            </Flex>
            <NonExposedNexusTable data={filteredNexus.non_exposed} isPending={isPending} />
          </Stack>
        ),
      },
    },
    ids: ['exposed-nexus-header', 'non-exposed-nexus-header'],
  };

  return (
    <>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        <NexusHeaderToolbar />
      </HStack>
      <Stack
        h="100%"
        css={{
          '& .chakra-tabs, & .chakra-tabs__tab-panels>.chakra-tabs__tab-panel': {
            height: '100%',
          },
          '& .chakra-tabs__tab-panels': {
            height: 'calc(100% - 2.5rem)',
          },
        }}
      >
        <Tabs.Root defaultValue={tabs.ids[0]} colorPalette="blue">
          <Tabs.List>
            {tabs.ids.map(key => {
              const typedKey = key as keyof typeof tabs.byId;
              return (
                <Tabs.Trigger _selected={{ color: 'secondary.500' }} key={key} value={key}>
                  <Text fontWeight="normal">{tabs.byId[typedKey].title}</Text>
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
          {tabs.ids.map(key => {
            const typedKey = key as keyof typeof tabs.byId;
            return (
              <Tabs.Content key={key} value={key}>
                {tabs.byId[typedKey].content}
              </Tabs.Content>
            );
          })}
        </Tabs.Root>
      </Stack>
      {open && <SetupPhysicalMailModal isOpen={open} onClose={onClose} isNexus={true} />}
    </>
  );
};

export default Nexus;
